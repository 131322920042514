import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import RadioSelector from "../RadioSelector";
import InputPhase from "../inputs/InputPhase";
import InputDate from "shared/components/inputs/InputDate";

import "./style.scss";
import  isValidStringDate  from "shared/helpers/date";

const PeriodSelector = ({
    hasInitiationDate = false,
    onChange,
    defaultPeriod,
    deadlines = [], hideRecurring, onDateChange
}) => {

    const { t } = useTranslation();

    const { phaseShift, every } = defaultPeriod;

    const [errorMessage, setErrorMessage] = useState(null);
    const [isRepeating, setIsRepeating] = useState(!!defaultPeriod?.every);

    const [repeatingTime, setRepeatingTime] = useState(every?.time || "years");
    const [repeatingTimes, setRepeatingTimes] = useState(every?.times || 1);

    const [expectedTime, setExpectedTime] = useState(phaseShift?.inExpected.time || "months");
    const [expectedTimes, setExpectedTimes] = useState(phaseShift?.inExpected.times || 1);

    const [attentionTime, setAttentionTime] = useState(phaseShift?.inAttention.time || "weeks");
    const [attentionTimes, setAttentionTimes] = useState(phaseShift?.inAttention.times || 1);

    const [criticalTime, setCriticalTime] = useState(phaseShift?.inCritical.time || "days");
    const [criticalTimes, setCriticalTimes] = useState(phaseShift?.inCritical.times || 3);

    const [initiationDate, setInitiationDate] = useState(defaultPeriod?.initiationDate || Date());

    useEffect(() => {
        const validations = validateFields();

        onChange({
            initiationDate: hasInitiationDate ? initiationDate : undefined,
            validations,
            every: isRepeating ? {
                time: repeatingTime,
                times: repeatingTimes,
            } : undefined,
            phaseShift: {
                inExpected: {
                    time: expectedTime,
                    times: expectedTimes,
                },
                inAttention: {
                    time: attentionTime,
                    times: attentionTimes,
                },
                inCritical: {
                    time: criticalTime,
                    times: criticalTimes,
                },
            },
            isRepeating
        });

    }, [
        initiationDate,
        repeatingTime, repeatingTimes,
        expectedTime, expectedTimes,
        attentionTime, attentionTimes,
        criticalTime, criticalTimes,
        isRepeating
    ]);


    const validateFields = () => {
        const multipliers = {
            years: 365,
            quarters: 365 / 4,
            months: 365 / 12,
            weeks: 7,
            days: 1
        };

        const expectedTotal = multipliers[expectedTime] * expectedTimes;
        const attentionTotal = multipliers[attentionTime] * attentionTimes;
        const criticalTotal = multipliers[criticalTime] * criticalTimes;
        const repeatingTotal = multipliers[repeatingTime] * repeatingTimes;

        if (isRepeating && expectedTotal >= repeatingTotal) {
            setErrorMessage(t("period.error.expectedTooHigh"));
            return false;
        }
        else if (attentionTotal >= expectedTotal) {
            setErrorMessage(t("period.error.attentionTooHigh"));
            return false;

        } else if (criticalTotal >= attentionTotal) {
            setErrorMessage(t("period.error.criticalTooHigh"));
            return false;
        }
        else {
            setErrorMessage(null);
            return true;
        }
    };

    const onInputDateChange = (date) => {
        if (isValidStringDate(date)) {
            setInitiationDate(date);
            if (onDateChange) {
                onDateChange(date);
            }
        }
    };

    return (
        <div className="period-selector">
            {!hideRecurring &&
                <RadioSelector
                    firstLabel={t("period.toggle.repeating")}
                    secondLabel={t("period.toggle.once")}
                    selected={isRepeating}
                    onHandleSelected={setIsRepeating}
                />}
            <div className="period-repeating">
                <div className="cols">
                    {hasInitiationDate &&
                        <div className="col">
                            <h5>{t("dossier.initiationDate")}</h5>
                            <div className="spacer" />
                            <InputDate
                                onBlur={onDateChange}
                                type="date"
                                name="initiationDate"
                                defaultValue={initiationDate}
                                label={isRepeating ? t("dossier.add.initiationDate.label")
                                    : t("dossier.add.endDate.label")}
                                onChange={onInputDateChange}
                            />
                        </div>}
                    {isRepeating &&
                        <div className="col">
                            <h5>{t("period.repeat.every")}</h5>
                            <div className="spacer" />
                            <InputPhase
                                name={"repeating"}
                                defaultValue={{
                                    time: repeatingTime,
                                    times: repeatingTimes
                                }}
                                setTime={setRepeatingTime}
                                setTimes={setRepeatingTimes}
                                onBlur={onDateChange}
                            />
                        </div>}
                </div>
            </div>
            <div className="period-phases">
                {!isRepeating && <div className="spacer" />}
                <div className="cols">
                    <div className="col">
                        <div className="phase-header">
                            {t("phases.inPhase.label")}
                            <span className="phase-expected">
                                {t("phases.inPhase.expected")}
                            </span>
                        </div>
                        <InputPhase
                            defaultValue={{
                                time: expectedTime,
                                times: expectedTimes
                            }}
                            setTime={setExpectedTime}
                            setTimes={setExpectedTimes}
                            deadline={typeof deadlines[0] !== "undefined" ?
                                deadlines[0].date.format("ll") : undefined}
                            noMarginBottom
                        />
                    </div>
                    <div className="col">
                        <div className="phase-header">
                            {t("phases.inPhase.label")}
                            <span className="phase-attention">
                                {t("phases.inPhase.attention")}
                            </span>
                        </div>
                        <InputPhase
                            defaultValue={{
                                time: attentionTime,
                                times: attentionTimes
                            }}
                            setTime={setAttentionTime}
                            setTimes={setAttentionTimes}
                            deadline={typeof deadlines[1] !== "undefined" ?
                                deadlines[1].date.format("ll") : undefined}
                            noMarginBottom
                        />
                    </div>
                    <div className="col">
                        <div className="phase-header">
                            {t("phases.inPhase.label")}
                            <span className="phase-critical">
                                {t("phases.inPhase.critical")}
                            </span>
                        </div>
                        <InputPhase
                            defaultValue={{
                                time: criticalTime,
                                times: criticalTimes
                            }}
                            setTime={setCriticalTime}
                            setTimes={setCriticalTimes}
                            deadline={typeof deadlines[2] !== "undefined" ?
                                deadlines[2].date.format("ll") : undefined}
                            noMarginBottom
                        />
                    </div>
                </div>
                {errorMessage && <div className="error-message">{errorMessage}</div>}

            </div>
        </div>
    );
};

PeriodSelector.propTypes = {
    hasInitiationDate: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    defaultPeriod: PropTypes.object,
    deadlines: PropTypes.array,
    hideRecurring: PropTypes.bool,
    onDateChange: PropTypes.func
};

export default PeriodSelector;

